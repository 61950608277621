/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { squaredDistance as e } from "../../../core/libs/gl-matrix-2/math/vec2.js";
class t {
  constructor(e, t, i) {
    this.maxSize = e, this._tileInfoView = t, this._removedFunc = i, this._tilePerId = new Map(), this._tileKeysPerLevel = [];
  }
  clear() {
    this._tilePerId.clear(), this._tileKeysPerLevel = [];
  }
  has(e) {
    return this._tilePerId.has(e);
  }
  get(e) {
    return this._tilePerId.get(e);
  }
  pop(e) {
    const t = this._tilePerId.get(e);
    if (!t) return;
    const r = t.key.level,
      s = this._tileKeysPerLevel[r];
    i(this._tilePerId, e);
    for (let i = 0; i < s.length; i++) if (s[i].id === e) {
      s.splice(i, 1);
      break;
    }
    return t.visible = !0, t;
  }
  add(e) {
    e.visible = !1;
    const t = e.key,
      i = t.id;
    if (this._tilePerId.has(i)) return;
    this._tilePerId.set(i, e);
    const r = t.level;
    this._tileKeysPerLevel[r] || (this._tileKeysPerLevel[r] = []), this._tileKeysPerLevel[r].push(t);
  }
  prune(e, t, i) {
    let r = this._tilePerId.size;
    if (r <= this.maxSize) return;
    let s = this._tileKeysPerLevel.length - 1;
    for (; r > this.maxSize && s >= 0;) s !== e && (r = this._pruneAroundCenterTile(r, t, i, s)), s--;
    r > this.maxSize && (r = this._pruneAroundCenterTile(r, t, i, e));
  }
  _pruneAroundCenterTile(t, i, r, s) {
    const l = this._tileKeysPerLevel[s];
    if (!l || 0 === l.length) return t;
    const {
        size: h,
        origin: n
      } = this._tileInfoView.tileInfo,
      o = r * h[0],
      _ = r * h[1],
      d = [0, 0],
      c = [0, 0];
    for (l.sort((t, r) => (d[0] = n.x + o * (t.col + .5), d[1] = n.y - _ * (t.row + .5), c[0] = n.x + o * (r.col + .5), c[1] = n.y - _ * (r.row + .5), e(d, i) - e(c, i))); l.length > 0;) {
      const e = l.pop();
      if (this._removeTile(e.id), --t === this.maxSize) break;
    }
    return t;
  }
  _removeTile(e) {
    const t = this._tilePerId.get(e);
    this._removedFunc && t && this._removedFunc(t), i(this._tilePerId, e);
  }
}
function i(e, t) {
  e.delete(t);
}
export { t as default };