/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import t from "../../../core/Accessor.js";
import { getOrCreateMapValue as s } from "../../../core/MapUtils.js";
import { destroyMaybe as o } from "../../../core/maybe.js";
import { someSet as r } from "../../../core/SetUtils.js";
import { property as i } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as c } from "../../../core/accessorSupport/decorators/subclass.js";
import { distance as u } from "../../../core/libs/gl-matrix-2/math/vec2.js";
import { QueueProcessor as n } from "../../support/QueueProcessor.js";
const l = [0, 0];
let h = class extends t {
  constructor(e) {
    super(e), this._keyToItem = new Map(), this._tilesByScale = new Map(), this.concurrency = 6;
  }
  initialize() {
    const {
      concurrency: e,
      process: t,
      scheduler: s,
      priority: o
    } = this;
    this._queue = new n({
      concurrency: e,
      scheduler: s,
      priority: o,
      process: (e, s) => {
        const o = this._keyToItem.get(e);
        return t(o, {
          signal: s
        });
      },
      peeker: e => this._peek(e)
    });
  }
  destroy() {
    this.clear(), this._queue = o(this._queue);
  }
  get length() {
    return this._queue ? this._queue.length : 0;
  }
  abort(e) {
    const t = "string" == typeof e ? e : e.id;
    this._queue.abort(t);
  }
  clear() {
    this._queue.clear(), this._keyToItem.clear(), this._tilesByScale.clear();
  }
  has(e) {
    return "string" == typeof e ? this._keyToItem.has(e) : this._keyToItem.has(e.id);
  }
  pause() {
    this._queue.pause();
  }
  push(e) {
    const t = e.key.id;
    if (this._queue.has(t)) return this._queue.get(t);
    const o = this._queue.push(t),
      r = this.tileInfoView.getTileScale(e.key),
      i = s(this._tilesByScale, r, () => new Set()),
      c = () => {
        i.delete(e.key), 0 === i.size && this._tilesByScale.delete(r), this._keyToItem.delete(t);
      };
    return i.add(e.key), this._keyToItem.set(t, e), o.then(c, c), o;
  }
  reset() {
    this._queue.reset();
  }
  resume() {
    this._queue.resume();
  }
  _peek(e) {
    if (!this.state) return e.values().next().value;
    const t = new Set();
    for (const r of e) t.add(this._keyToItem.get(r).key);
    const s = this.state.scale;
    let o,
      i = Number.POSITIVE_INFINITY;
    for (const [c, u] of this._tilesByScale) {
      if (r(u, e => t.has(e))) {
        const e = Math.abs(c - s);
        e < i && (o = u, i = e);
      }
    }
    return this._getClosestTileKey(o, e).id;
  }
  _getClosestTileKey(e, t) {
    const s = this.tileInfoView,
      o = this.state.center;
    let r,
      i = Number.POSITIVE_INFINITY;
    for (const c of e) if (t.has(c.id)) {
      s.getTileCoords(l, c);
      const e = u(l, o);
      e < i && (i = e, r = c);
    }
    return r;
  }
};
e([i({
  constructOnly: !0
})], h.prototype, "concurrency", void 0), e([i({
  constructOnly: !0
})], h.prototype, "priority", void 0), e([i({
  constructOnly: !0
})], h.prototype, "process", void 0), e([i({
  constructOnly: !0
})], h.prototype, "scheduler", void 0), e([i()], h.prototype, "state", void 0), e([i({
  constructOnly: !0
})], h.prototype, "tileInfoView", void 0), h = e([c("esri.views.2d.tiling.TileQueue")], h);
const p = h;
export { p as default };